@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;
  
  @media (--viewportSmall) {
    text-align: center;
  }

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.subHeading {
  text-align: center;
  font-size: 14px;

  @media (--viewportSmall) {
    font-size: 24px;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 763px;
  }
}

.coverImage {
  display: block;
  max-width: 300px;
  margin: 0 auto;
}