@import '../../marketplace.css';

.error {
  color: var(--failColor);
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  white-space: nowrap;
}

.modalHeader {
  display: block;
  padding-top: 20px;
}
