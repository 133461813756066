@import '../../marketplace.css';

.root {
  margin-top: 48px;
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.subTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.field {
  width: 100%;
}

.firstName {
  width: calc(50% - 9px);
}

.lastName {
  width: calc(50% - 9px);
}

.selectCountry {
  margin-bottom: 24px;
}

.postalCode {
  margin-top: 24px;
  width: calc(40% - 9px);
}

.city {
  margin-top: 24px;
  width: calc(60% - 9px);
}

.selectMCC,
.textInputRow {
  margin-bottom: 24px;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.termsText {
  @apply --marketplaceModalHelperText;
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  @apply --marketplaceH4FontStyles;
}

.missingStripeKey {
  color: var(--failColor);
}

.personalAddressContainer {
  margin-bottom: 28px;
}

.fieldArrayAdd {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceSearchFilterSublabelFontStyles;
  font-weight: var(--fontWeightMedium);
  margin-bottom: 2px;
}

.fieldArrayRemove {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  float: right;
  line-height: 20px;

  &:hover {
    color: var(--matterColor);
  }
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
  margin-right: 5px;
}

.addIcon {
  margin-right: 7px;
  display: inline-block;
  height: 18px;
  padding-top: 1px;
}

.roleField {
  border: 0;
  padding: 0;
  margin-bottom: 24px;
}

.ownershipPercentage {
  position: relative;
  max-width: 90px;
  padding-right: 24px;
  margin-bottom: 24px;
  white-space: nowrap;

  &:after {
    content: '%';
    position: absolute;
    top: 36px;
    right: 6px;
  }
}

/* Company account opener */
.accountOpenerWrapper {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}
.accountOpenerInputsWrapper .sectionContainer {
  margin-bottom: 24px;
}

.accountOpenerInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

/* Company owners and directors */
.additionalPersonsWrapper {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}
.additionalPersonWrapper .sectionContainer {
  margin-bottom: 24px;
}

.additionalPersonLabel {
  display: inline-block;
}

.additionalPersonInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}
