@import '../../marketplace.css';



.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto 150px;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto 150px;
  }
}


.sectionTitle {
  margin-bottom: 0;
}