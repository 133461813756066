@import '../../marketplace.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

input[disabled] {
  background-color: #e7e7e7;
}
