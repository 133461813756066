@import '../../marketplace.css';

.root {
  width: 100%;
  position: relative;
}

.footerButtonsHolder {  
  position: fixed;
  z-index: 10;

  transition: none;
  bottom: 15px;
  right: 24px;
  left: 24px;
  width: calc(100vw - 48px);
  background: var(--marketplaceColor);
  padding: 0 12px;
  border-radius: 2px;

  @media (--viewportMedium) {
    min-height: 40px;
    line-height: 40px;
  }

  @media (--viewportMedium) {
    left: auto;
    width: auto;
    padding: 0 16px;
  }
}

.footerButtonMore {
  display: inline-block;
  width: auto;
  min-height: 20px;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  
  @media (--viewportSmall) {
    min-height: 40px;
    font-size: 18px;
  }

  &:hover,
  &:focus {
    background: var(--marketplaceColor);
    box-shadow: none;
  }
}


.footerContainer {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0;
  top: 60px;
  padding-bottom: 55px;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    padding-bottom: 0;
    top: auto;
  }
}

.footerCategoryLink {
  display: inline-block;
  width: 48%;
  margin-right: 0;
  color: var(--matterColorLight);
  font-size: 14px;
  line-height: 1;

  @media (--viewportSmall) {
    display: inline;
    width: auto;
    margin-right: 12px;
    font-size: 18px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}